import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Loader from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner';
import Header from '../Includes/Header';

const Login = ({ orderId, barcode, onChange, login, message, error, isLoading }) => {

  const [showScanner, setShowScanner] = useState(false);
  let arrCB = null;

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        // .then((devices) => {
        //   devices.forEach((device) => {
        //     console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
        //   });
        // })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
  }, []);

  const handleScan = useCallback((data) => {
    setShowScanner(false);
    if (data) {
      onChange({ target: { name: 'barcode', value: data } });
    }
  }, [onChange]);

  const executeCB = (cb) => {
    arrCB = cb;
  }

  const handleError = useCallback((err) => {
    console.error('err', err);
  }, []);

  return (
    <>
      {showScanner && <Container fluid className="w-100 text-center">
        <Row className='my-4'>
          <Col>
            <h4>Scan your ticket barcode</h4>
            <p>Place the barcode in view of the camera and keep the device steady until the barcode is captured. Ensure you have good lighting for best results.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <BarcodeScanner handleScan={handleScan} handleError={handleError} executeCB={executeCB} />
          </Col>
        </Row>
        <Row>
          <Col>
            <button className='button -primary my-4 p-4' onClick={() => {
              if (arrCB) arrCB();
              setShowScanner(false)
            }}>Close</button>
          </Col>
        </Row>
      </Container>}

      {!showScanner && <Container className='mt-1 text-center px-0'>
        <Header />
        <Row className='p-2 align-items-end'>
          <Col md={6} className='pt-2 px-0'>
            <form className='form-control-group -primary'>
              <h4 className="text-left">Order ID</h4>
              <div className='form-control-wrap form-control-select-wrapper'>
                <input
                  disabled={isLoading}
                  className='form-control'
                  required
                  name="orderId"
                  id="orderId"
                  type="numeric"
                  value={orderId}
                  onChange={onChange}
                />
              </div>
            </form>
          </Col>
          <Col md={5} xs={9} className='pt-2 px-0'>
            <form className='form-control-group -primary'>
              <h4 className="text-left">Barcode</h4>
              <div className='form-control-wrap form-control-select-wrapper'>
                <input
                  disabled={isLoading}
                  className='form-control'
                  required name="barcode"
                  id="barcode"
                  type="text"
                  value={barcode}
                  onChange={onChange}
                  placeholder='Input / Camera Scan'
                />
              </div>
            </form>
          </Col>
          <Col md={1} xs={3} className='text-left align-items-middle px-0'>
            <button className='button -primary -large my-0 p-4 mr-0' onClick={() => setShowScanner(true)}><FontAwesomeIcon icon={faCamera} /></button>
          </Col>
        </Row>

        <Row className='mt-5 text-center'>
          <Col>
            {!isLoading ?
              <button
                disabled={isLoading || [barcode, orderId].some(field => !field)}
                className='button -tertiary -large'
                onClick={login}
              > Submit</button>
              : <><h3>Finding your info...</h3><Loader /></>}
          </Col>
        </Row>
        <Row className='p-3 mt-3'>
          <Col md={12} className={(error ? "text-danger border border-danger " : "") + "font-weight-bold text-center text-uppercase p-3"}>
            {message}&nbsp;
          </Col>
        </Row>
      </Container>}
    </>
  );
};

export default Login;
