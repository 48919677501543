import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Login from '../Login/Login';
import Register from '../Login/Register';
import Profile from '../Profile/Profile';
import Excluded from '../Excluded/Excluded';
import TagManager from 'react-gtm-module';
import LoginSamples from '../Includes/LoginSamples';

const Home = (props) => {
    const pathnameParts = props.location.pathname.split('/');
    const initialOrderId = pathnameParts[1] || '';
    const initialBarcode = pathnameParts[2] || '';

    const { history } = props;

    const [orderId, setOrderId] = useState(initialOrderId);
    const [barcode, setBarcode] = useState(initialBarcode);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [optInEmailMarketing, setOptInEmailMarketing] = useState(false);
    const [optInSMSMarketing, setOptInSMSMarketing] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [photoImage, setPhotoImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [registerAgain, setRegisterAgain] = useState(false);
    const [isPassActivated, setIsPassActivated] = useState(false);
    const [excluded, setExcluded] = useState(null);

    const setters = {
        optInEmailMarketing: setOptInEmailMarketing,
        optInSMSMarketing: setOptInSMSMarketing,
        firstName: setFirstName,
        lastName: setLastName,
        email: setEmail,
        phone: setPhone,
        orderId: setOrderId,
        barcode: setBarcode,
        hasPhoto: setHasPhoto,
        photoImage: setPhotoImage,
        isPassActivated: setIsPassActivated,
        excluded: setExcluded
    };

    const login = useCallback(async () => {
        if (!orderId || !barcode) return;
        setIsLoading(true);
        setMessage('');
        setError(false);
        setExcluded(null);
        try {
            const response = await fetch(`/api/login/`, {
                method: 'POST',
                body: JSON.stringify({ orderId, barcode }),
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) { // response.ok is true for any status 2xx
                const results = await response.json();
                setMessage('');
                setError(false);
                setData(results);
                setFirstName(results.firstName || '');
                setLastName(results.lastName || '');
                setEmail(results.email || '');
                setPhone(results.phone || '');
                setOptInEmailMarketing(results.optInEmailMarketing || false);
                setOptInSMSMarketing(results.optInSMSMarketing || false);
                setIsPassActivated(results.isPassActivated || false);
                setExcluded(results.excluded);
                window.top.postMessage('navTop', '*');
                history.push(`/${orderId}/${results.barcode}`);
            } else {
                switch (response.status) {
                    case 408:
                        setMessage('Temporary server error, please retry');
                        setError(true);
                        break;
                    default:
                        setMessage('Unable to locate order');
                        setError(true);
                }
            }
        } catch (error) {
            setMessage('Error logging in');
            setError(true);
        } finally {
            setIsLoading(false); // Ensure this is called no matter what
        }
    }, [orderId, barcode, history]);

    useEffect(() => {
        login();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM,
            dataLayer: {
                userId: orderId && barcode ? `${orderId}_${barcode}` : 0,
                userProject: 'digital-wallet',
                page: window.location.pathname,
            },
            dataLayerName: 'PageDataLayer',
        };
        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer(tagManagerArgs);
    }, [orderId, barcode]);

    const onChange = (e) => {
        const { name, value, type, checked } = e.target;
        const setValue = setters[name];

        if (!setValue) return;

        switch (type) {
            case "checkbox":
                setValue(checked);
                break;
            default:
                setValue(value);
        }
    };

    const updateMessage = (obj) => {
        setMessage(obj.message);
        setError(obj.error);
    };

    const register = async () => {
        setIsLoading(true);
        setMessage('');
        setError(false);
        try {
            const response = await fetch(`/api/register/`, {
                method: 'POST',
                body: JSON.stringify({ orderId, barcode, firstName, lastName, email, phone, optInEmailMarketing, optInSMSMarketing }),
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) { // response.ok is true for any status 2xx
                const results = await response.json();
                setMessage('');
                setError(false);
                setData(results);
                setRegisterAgain(false);
                window.top.postMessage('navTop', '*');
                history.push(`/${orderId}/${barcode}`);
            } else {
                switch (response.status) {
                    case 408:
                        setMessage('Temporary server error, please retry');
                        setError(true);
                        break;
                    default:
                        setMessage('Unable to register');
                        setError(true);
                }
            }
        } catch (error) {
            setMessage('Error registering');
            setError(true);
        } finally {
            setIsLoading(false); // Ensure this is called no matter what
        }
    };

    const logout = () => {
        window.top.postMessage('navTop', '*');
        setOrderId('');
        setBarcode('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setOptInEmailMarketing(false);
        setOptInSMSMarketing(false);
        setMessage('');
        setHasPhoto(false);
        setIsLoading(false);
        setError(false);
        setData(null);
        history.push('/');
    };

    return (
        <>
            <Container className='mt-1 mx-0 p-0' style={{ maxWidth: 'inherit' }}>
                {(!data) &&
                    <Login
                        orderId={orderId}
                        barcode={barcode}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        phone={phone}
                        optInEmailMarketing={optInEmailMarketing}
                        optInSMSMarketing={optInSMSMarketing}
                        message={message}
                        error={error}
                        hasPhoto={hasPhoto}
                        isLoading={isLoading}
                        onChange={onChange}
                        login={login}
                        setMessage={updateMessage}
                    />}

                {data && !data.skipRegistration && !excluded && (!data.firstName || !data.lastName || registerAgain) &&
                    <>
                        <Register
                            orderId={orderId}
                            barcode={barcode}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}
                            phone={phone}
                            optInEmailMarketing={optInEmailMarketing}
                            optInSMSMarketing={optInSMSMarketing}
                            message={message}
                            error={error}
                            hasPhoto={hasPhoto}
                            isLoading={isLoading}
                            onChange={onChange}
                            register={register}
                            setMessage={updateMessage}
                        />
                        <Row className='border-top pt-5 mt-5 text-center'>
                            <Col>
                                <button className='button -secondary mt-5 mb-5' onClick={logout}>Start Over</button>
                            </Col>
                        </Row>
                    </>
                }
                {data && !excluded && ((data.firstName && data.lastName && !registerAgain) || data.skipRegistration) &&
                    <>
                        <Profile
                            orderId={orderId}
                            barcode={barcode}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}
                            phone={phone}
                            optInEmailMarketing={optInEmailMarketing}
                            optInSMSMarketing={optInSMSMarketing}
                            message={message}
                            error={error}
                            hasPhoto={hasPhoto}
                            photoImage={photoImage}
                            isLoading={isLoading}
                            isPassActivated={isPassActivated}
                            data={data}
                            setMessage={updateMessage}
                            onChange={onChange}
                        />
                        <Row className='border-top pt-5 mt-5 text-center'>
                            <Col>
                                {data.email && <button className='button -primary mt-5 mb-5 mr-2' onClick={() => setRegisterAgain(true)}>Edit Registration</button>}
                                <button className='button -secondary mt-5 mb-5' onClick={logout}>Start Over</button>
                            </Col>
                        </Row>
                    </>
                }
                {data && excluded &&
                    <>
                        <Excluded
                            orderId={orderId}
                            barcode={barcode}
                            excluded={excluded}
                        />
                        <Row className='border-top text-center'>
                            <Col>
                                <button className='button -secondary mt-5 mb-5' onClick={logout}>Start Over</button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <LoginSamples
                orderId={orderId}
                barcode={barcode}
            />
        </>
    );
};

export default Home;
