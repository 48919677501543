import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Photo from '../Photo/Photo';
import Loader from '../Loader/Loader';
import PassPreview from '../Includes/PassPreview';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Profile = ({
  data,
  orderId,
  barcode,
  setMessage,
  hasPhoto,
  photoImage,
  error,
  message,
  onChange,
  isPassActivated
}) => {
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingApple, setLoadingApple] = useState(false);
  const [loadingVoucher, setLoadingVoucher] = useState(false);

  const getAppleWallet = async () => {
    if (orderId && barcode) {
      setLoadingApple(true);
      try {
        const response = await fetch(`/api/apple-wallet/`, {
          method: 'POST',
          body: JSON.stringify({ orderId, barcode }),
          headers: { 'Content-Type': 'application/json' },
        })

        if (response.ok) { // response.ok is true for any status 2xx
          const blob = await response.blob();
          if (blob && blob.type === 'application/vnd.apple.pkpass') {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Moreys.pkpass';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setLoadingApple(false);
            setMessage({ message: '', error: false });
          }
        } else {
          setLoadingApple(false);
          switch (response.status) {
            case 408:
              setMessage({ message: 'Temporary server error, please retry', error: true });
              break;
            default:
              setMessage({ message: 'Unable to locate order', error: true });
          }
        }
      } catch (error) {
        setLoadingApple(false);
        setMessage({ message: 'Unable to process your request', error: true });
      }
    }

  };

  const getGoogleWallet = async () => {
    if (orderId && barcode) {
      setLoadingGoogle(true);
      try {

        const response = await fetch(`/api/google-wallet/`, {
          method: 'POST',
          body: JSON.stringify({
            orderId,
            barcode,
            hasPhoto
          }),
          headers: { 'Content-Type': 'application/json' },
        })

        if (response.ok) { // response.ok is true for any status 2xx
          const url = await response.text();
          setTimeout(() => {
            window.open(url, '_blank');
          });
          setLoadingGoogle(false);
          setMessage({
            message: <h3><a className='button -primary -small' href={url} target='_blank' rel="noreferrer">View Your Pass</a></h3>,
            error: false
          });
        } else {
          setLoadingGoogle(false);
          switch (response.status) {
            case 408:
              setMessage({ message: 'Temporary server error, please retry', error: true });
              break;
            default:
              setMessage({ message: 'Unable to locate order', error: true });
          }
        }
      } catch (error) {
        setLoadingGoogle(false);
        setMessage({ message: `Unable to process your request`, error: true });
      }
    }
  }

  const getVoucher = async () => {
    if (orderId && barcode) {
      setLoadingVoucher(true);
      try {

        const response = await fetch(`/api/voucher/`, {
          method: 'POST',
          body: JSON.stringify({
            orderId,
            barcode,
          }),
          headers: { 'Content-Type': 'application/json' },
        })

        if (response.ok) { // response.ok is true for any status 2xx
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `moreys_voucher_${barcode}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingVoucher(false);
        } else {
          setLoadingVoucher(false);
          switch (response.status) {
            case 408:
              setMessage({ message: 'Temporary server error, please retry', error: true });
              break;
            default:
              setMessage({ message: 'Unable to locate order', error: true });
          }
        }
      } catch (error) {
        setLoadingVoucher(false);
        setMessage({ message: `Unable to process your request`, error: true });
      }
    }
  }

  const hasPurchaser = data.purchaserFirstName && data.purchaserLastName;

  return (
    <Container className='mt-4' style={{ maxWidth: '920px' }}>
      <Row>
        <Col md={4}>
          <PassPreview {...{ ...data, photoImage: photoImage, hasPurchaser: hasPurchaser }} />
        </Col>
        <Col className='px-5'>

          {/* {(!data.expires || 1 === 1 || (data.expires && new Date(data.expires) > new Date())) ? */}
          <>
            {((data.isPassholder && hasPhoto) || !data.isPassholder || data.skipImage) &&
              <MobileView>
                <Row className='mt-5 text-center bg-black'>
                  <Col className="mt-3 px-0">
                    <button disabled={loadingApple || orderId === 0} onClick={getAppleWallet}>
                      <img src="/apple-wallet.svg" className='wallet' alt="Add to Apple Wallet" />
                    </button>
                  </Col>
                  <Col className='mt-3 px-0'>
                    <button disabled={loadingGoogle || orderId === 0} onClick={getGoogleWallet}>
                      <img src="/google-wallet.svg" className='wallet' alt="Add to Google Wallet" />
                    </button>
                  </Col>
                </Row>
              </MobileView>}
            <BrowserView>
              <Row className='mt-5 text-center'>
                <Col className='mt-3 mx-0'>
                  <button disabled={loadingVoucher || orderId === 0} onClick={getVoucher} id="voucherButton">
                    Print Voucher
                  </button>
                </Col>
              </Row>
              <Row className='mt-0 text-center'>
                <Col className='mt-3 mx-0'>
                  <h5>Use a mobile device to add pass to your digital wallet</h5>
                </Col>
              </Row>
            </BrowserView>
            <Row className='mt-4 text-center'>
              <Col>
                {loadingApple || loadingGoogle ? <><h3>Generating Pass — Please wait...</h3><Loader /></> : null}
              </Col>
            </Row>

            {data.isPassholder && !data.skipImage &&
              <Row className=''>
                <Col className='px-0'>
                  <Photo
                    isPassActivated={isPassActivated}
                    onChange={onChange}
                    orderId={orderId}
                    {...{ data, setMessage }}
                  />
                </Col>
              </Row>}

            {message &&
              <Row className='p-0 mt-4'>
                <Col md={12} className={(error ? "text-danger border border-danger p-3 " : "") + "font-weight-bold text-center px-0"}>
                  {message}
                </Col>
              </Row>}

          </>
          {/* :
            <Row className='mt-4 text-center'>
              <Col className='text-danger border border-danger p-3'>
                <h2 className='text-danger my-2'>Pass Expired</h2>
              </Col>
            </Row>} */}
        </Col>
      </Row>
    </Container>
  );

};

export default Profile;