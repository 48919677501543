import React, { useState } from 'react'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
const Cropper = (props) => {
    const [crop, setCrop] = useState()
    const [aspect, setAspect] = useState(1.333333)
    const [isLoaded, setLoaded] = useState(false);
    const [mediaWidth, setMediaWidth] = useState(0);
    const [mediaHeight, setMediaHeight] = useState(0);

    const { imgRef, boundingBox, isLoading, facesDetected } = useFaceDetection({
        faceDetectionOptions: {
            model: 'short',
        },
        faceDetection: new FaceDetection.FaceDetection({
            locateFile: (file) => `${process.env.REACT_APP_PUBLIC_URL}/${file}`
        }),
    });

    if (!isLoading && !isLoaded) {
        setLoaded(true);
        switch (facesDetected) {
            case 0:
                props.setMessage({
                    message: 'A face was not detected in this image, possibly due to a low quality image or other factors. If you choose to continue, be aware that any guest photographs that do not meet our requirements may be rejected.',
                    error: true
                });
                setCrop(centerAspectCrop(mediaWidth, mediaHeight, aspect, 90))
                break;
            case 1:
                props.setMessage({
                    message: '',
                    error: false
                });
                let boundObj = {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 0,
                    width: boundingBox[0].width * 100,
                    centerX: boundingBox[0].xCenter * 100 + (boundingBox[0].width * 100 / 2),
                    centerY: boundingBox[0].yCenter * 100 + (boundingBox[0].height * 100 / 2),
                    xDelta1: 0,
                    xDelta2: 0,
                    yDelta1: 0,
                    yDelta2: 0
                }
                boundObj.width = Math.round(boundObj.width * 2.25);
                boundObj.x1 = boundObj.centerX - (boundObj.width / 2);
                boundObj.x2 = boundObj.centerX + (boundObj.width / 2);
                if (boundObj.x1 < 0) boundObj.xDelta1 = 0 - boundObj.x1;
                if (boundObj.x2 > 100) boundObj.xDelta2 = boundObj.x2 - 100;
                boundObj.width = boundObj.width - boundObj.xDelta1 - boundObj.xDelta2;
                boundObj.x1 += boundObj.xDelta1;
                boundObj.x2 -= boundObj.xDelta2;
                let obj = centerAspectCrop(mediaWidth, mediaHeight, aspect, boundObj.width);
                boundObj.y1 = boundObj.centerY - (obj.height / 2);
                boundObj.y2 = boundObj.centerY + (obj.height / 2);
                if (boundObj.y1 < 0) boundObj.yDelta1 = 0 - boundObj.y1;
                if (boundObj.y2 > 100) boundObj.yDelta2 = boundObj.y2 - 100;
                boundObj.y1 += boundObj.yDelta1;
                boundObj.y2 += boundObj.yDelta1;
                boundObj.y1 -= boundObj.yDelta2;
                boundObj.y2 -= boundObj.yDelta2;
                setCrop({
                    width: obj.width,
                    height: obj.height,
                    x: boundObj.x1,
                    y: Math.max(0, boundObj.y1),
                    unit: '%'
                })
                break;
            default:
                props.setMessage({
                    message: 'Multiple faces have been detected. If you choose to continue, be aware that any guest photographs that do not meet our requirements may be rejected.',
                    error: true
                })
                setCrop(centerAspectCrop(mediaWidth, mediaHeight, aspect, 90))
                break;
        }
    }

    function centerAspectCrop(mediaWidth, mediaHeight, aspect, width) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: width,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function onImageLoad(e) {
        setAspect(1.333333)
        if (aspect) {
            const { width, height } = e.currentTarget
            setMediaWidth(width);
            setMediaHeight(height);
        }
    }

    return (
        <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(_, percentCrop) => props.setCrop(percentCrop)}
            aspect={aspect}
        >
            <img
                ref={imgRef}
                alt="Crop me"
                src={props.selectedFile.base64}
                onLoad={onImageLoad}
            />
        </ReactCrop>
    )
}

export default Cropper;
