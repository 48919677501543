import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

const StoredValue = ({ location }) => {
  const [barcodeHash] = useState(location.pathname.split('/')[2] || '');
  const [barcode, setBarcode] = useState('');
  const [attractions, setAttractions] = useState([]);
  const [credits, setCredits] = useState(-1);
  const [cash, setCash] = useState(-1);
  const [today, setToday] = useState('');
  const [loadingSV, setLoadingSV] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const getStoredValue = async () => {
      if (barcodeHash) {
        setLoadingSV(true);
        try {
          const response = await fetch(`/api/stored-value/`, {
            method: 'POST',
            body: JSON.stringify({ barcodeHash }),
            headers: { 'Content-Type': 'application/json' },
          })

          if (response.ok) {
            const data = await response.json();
            setCredits(data.credits);
            setCash(data.cash);
            setBarcode(data.barcode);
            setToday(data.today);
            setError(false);
            setLoadingSV(false);
            setAttractions(data.attractions);
          } else {
            setCredits(-1);
            setCash(-1);
            setBarcode('');
            setToday('');
            setError(true);
            setLoadingSV(false);
            setAttractions([]);
            switch (response.status) {
              case 408:
                setMessage('Temporary server error, please retry');
                break;
              default:
                setMessage('This may be a problem with your card or with our systems, please try again later.');
            }
          }
        } catch (error) {
          setCredits(-1);
          setCash(-1);
          setBarcode('');
          setToday('');
          setError(true);
          setLoadingSV(false);
          setAttractions([]);
          setMessage('Unable to process your request');
        }
      }
    };

    getStoredValue();
  }, [barcodeHash]);

  const formatDate = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Options for formatting the date portion
    const dateOptions = {
      // weekday: 'short', // Short name of the day
      month: 'numeric', // Numeric month
      day: 'numeric', // Numeric day of the month
      year: '2-digit' // Two-digit year
    };

    // Format the date and time using toLocaleString with specified options
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);

    return `${formattedDate}`;
  }

  const formatTimestamp = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Options for formatting the date portion
    // const dateOptions = {
    //   weekday: 'short', // Short name of the day
    //   month: 'numeric', // Numeric month
    //   day: 'numeric', // Numeric day of the month
    //   year: '2-digit' // Two-digit year
    // };

    // Options for formatting the time portion
    const timeOptions = {
      hour: 'numeric', // Numeric hour
      minute: '2-digit', // Two-digit minute
      hour12: true // Use 12-hour time format
    };

    // Format the date and time using toLocaleString with specified options
    // const formattedDate = date.toLocaleDateString('en-US', dateOptions).replace(',','');
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    // Combine the formatted date and time
    // return `${formattedDate} ${formattedTime}`;
    return `${formattedTime}`;
  }

  return (
    <Container className='mt-4 text-center'>
      {loadingSV && (
        <Row>
          <Col>
            <h3>Loading...</h3>
          </Col>
        </Row>
      )}
      {!error && cash > 0 && !credits && (
        <Row>
          <Col>
            <h2>Gift Certificate</h2>
            <h3>{barcode}</h3>
            <h1 className='mt-5'>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(cash)} Balance</h1>
          </Col>
        </Row>
      )}
      {!error && credits > 0 && !cash && (
        <Row>
          <Col>
            <h2>Stored Value Card</h2>
            <h3>{barcode}</h3>
            <h1 className='mt-5'>{credits} Credits</h1>
          </Col>
        </Row>
      )}
      {!error && !credits && !cash && (
        <Row>
          <Col>
            <h3>{barcode}</h3>
            <h1 className='mt-5'>Zero Balance</h1>
          </Col>
        </Row>
      )}
      {attractions.length > 0 && <>
        {/* <h2 className='mt-5'>Last {attractions.length > 1 && attractions.length} Attraction{attractions.length === 1 ? '' : 's'}</h2> */}
        <hr />
        <h3 className='mt-5'>Attraction History {formatDate(today)}</h3>
        <table className='mx-auto'
        //  style={{ width: '320px' }}
        >

          <tbody>
            {attractions.map((attraction, attractionIndex) => (
              <tr key={attractionIndex} className="mt-4">
                <td width="55%">
                  <h2 className='my-1' style={{ lineHeight: .95 }}>{attraction.attraction}</h2>
                  <h6 className='my-0'>{attraction.location}</h6>
                  {/* <p className="my-0">{formatTimestamp(attraction.timestamp)}</p> */}
                </td>
                <td width="45%" className='text-center'>
                  <div
                  // style={{ width: '60px', height: '60px' }} className="d-flex rounded-circle bg-primary justify-content-center align-items-center"
                  >
                    <h3 className='text-secondary'>{formatTimestamp(attraction.timestamp)}</h3>
                    {/* <h2 className='text-white m-0'>{attraction.credits * -1}</h2> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p style={{ fontSize: '13px', lineHeight: .95 }} className='text-secondary mt-5'>Attraction history is only available for the current operating day and resets at 2:00 AM. For assistance related to any visit prior to today please stop by guest services or email us at <a href="mailto:info@moreyspiers.com">info@moreyspiers.com</a>.</p>
      </>
      }
      {error && (
        <Row>
          <Col>
            <h4 className='text-danger'>Unable to retrieve ticket card data</h4>
            <p>{message}</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default StoredValue;
