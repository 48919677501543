import React from 'react';
import Barcode from 'react-barcode';

const showBalanceCredits = (category) => {
    return ['General','Stored Value Card'].includes(category);
}

const PassPreview = ({ barcode, backgroundColor, foregroundColor, isPassholder, skipImage, uid, category, credits, cash, firstName, lastName,
    purchaserFirstName, purchaserLastName, orderDate, redeemed, redeemedDate, expires, product, refunded, hasPurchaser, photoImage }) => {

    return (
        <div id="passContainer" style={{ backgroundColor: backgroundColor, color: foregroundColor }}>
            {isPassholder && !photoImage && !skipImage && <div id="passDisclaimer">
                <h4>This is not your wild pass card!</h4>
                You must upload a photo to activate your pass and download your digital card.
            </div>}
            <div id="passHeader">
                <div id="passLogo">
                    <img src="/moreys-logo.png" alt="Morey's Piers Logo" />
                </div>
                {isPassholder && !skipImage && uid && photoImage &&
                    <div id="passPhoto">
                        <img src={photoImage} alt="Pass Holder" />
                    </div>
                }
                {cash != null && cash > 0 && !credits && !uid && showBalanceCredits(category) &&
                    <div id="passCredits">
                        <div className="passLabel">Balance</div>
                        <div className="passValue">{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(cash)}</div>
                    </div>
                }
                {credits != null && credits > 0 && !uid && showBalanceCredits(category) &&
                    <div id="passCredits">
                        <div className="passLabel">Credits</div>
                        <div className="passValue">{credits}</div>
                    </div>
                }
                {!credits && !cash && !uid && showBalanceCredits(category) &&
                    <div id="passCredits">
                        <div className="passLabel">Balance</div>
                        <div className="passValue">0</div>
                    </div>
                }
            </div>
            {firstName && lastName &&
                <div id="passTicketholder">
                    <div className="passLabel">Ticketholder</div>
                    <div className="passValueLarge">{firstName} {lastName}</div>
                </div>
            }
            {hasPurchaser && firstName !== purchaserFirstName && lastName !== purchaserLastName &&
                <div id="passTicketPurchaser">
                    <div className="passLabel">Purchaser</div>
                    <div className="passValue">{purchaserFirstName} {purchaserLastName}</div>
                </div>
            }
            {orderDate &&
                <div id="passOrderDate">
                    <div className="passLabel">Order Date</div>
                    <div className="passValue">{orderDate}{redeemed && <em><br />Redeemed {redeemedDate}</em>}</div>
                </div>
            }
            {expires &&
                <div id="passExpireDate">
                    <div className="passLabel">Expires</div>
                    <div className="passValue">{expires}</div>
                </div>
            }
            {product &&
                <div id="passProduct">
                    <div className="passLabel">Product</div>
                    <div className="passValue">{product}{!isPassholder && refunded && <em><br />Refunded</em>}</div>
                </div>
            }
            {/*isPassholder && !photoImage &&*/<Barcode
                id="passBarcode"
                height={50}
                fontSize={14}
                value={barcode}
            />}
        </div>
    );
};

export default PassPreview;
