import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../Includes/Header';

const Excluded = ({
  excluded,
  orderId,
  barcode
}) => {

  return (
    <Container className='mt-1 text-center px-0'>
      <Header />
      <Row className='p-2 align-items-end'>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Order ID</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                disabled
                className='form-control'
                value={orderId}
                type="text"
              />
            </div>
          </form>
        </Col>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Barcode</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                disabled
                className='form-control'
                value={barcode}
                type="text"
              />
            </div>
          </form>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col md={12} className="border border-danger pt-4 pb-2 px-4">
          <p className='text-danger font-weight-bold'>{excluded}</p>
        </Col>
      </Row>

    </Container >
  );

};

export default Excluded;