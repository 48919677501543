import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Cropper from './Cropper';
import Loader from '../Loader/Loader';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import 'react-image-crop/dist/ReactCrop.css';

const Photo = ({ setMessage, orderId, barcode, data, onChange, isPassActivated }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [crop, setCrop] = useState(null);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageExists, setImageExists] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [hidePhotoRequest, setHidePhotoRequest] = useState(false);

    useEffect(() => {
        getPhoto();
        // eslint-disable-next-line
    }, [data.uid]);

    const onFileChange = (e) => {
        let f = e.target.files[0];
        if (f.size > process.env.REACT_APP_FILE_SIZE_LIMIT) {
            setMessage({
                message: 'File size is too large',
                error: true
            });
        } else {
            setMessage({
                message: '',
                error: false
            });
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = () => {
                let file = {
                    name: f.name,
                    type: f.type,
                    size: f.size,
                    base64: reader.result,
                };
                setSelectedFile(file);
                window.top.postMessage('navTop|100', '*');
            };
        }
    };

    const getPhoto = () => {
        fetch(`/api/download/${data.uid}/photo.bmp`, {
            method: 'GET',
        })
            .then(results => results.status < 400 ? results.text() : 'error')
            .then(results => {
                if (results !== 'error') {
                    setImageLoaded(true);
                    setImageExists(true);
                    setNewImage(results);
                    setIsUploading(false);
                    setIsPreviewing(false);
                    onChange({ target: { name: 'hasPhoto', value: true } });
                    onChange({ target: { name: 'photoImage', value: results } });
                } else {
                    setImageLoaded(true);
                    setImageExists(false);
                    setNewImage(null);
                    setIsUploading(false);
                    setIsPreviewing(false);
                    onChange({ target: { name: 'photoImage', value: null } });
                }
            });
    };

    const onFilePreview = () => {
        setIsPreviewing(true);
        fetch(`/api/preview/`, {
            method: 'POST',
            body: JSON.stringify({
                filename: selectedFile.name,
                filesize: selectedFile.size,
                filetype: selectedFile.type,
                data: selectedFile.base64,
                barcode,
                crop,
                orderId,
                uid: data.uid
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json().then(results => {
                        setIsPreviewing(false);
                        if (results.base64) {
                            setPreview(results);
                            setMessage({
                                message: '',
                                error: false // Assuming you want to mark this as not an error when successful
                            });
                        } else {
                            // Handle case where response is OK but not in the expected format
                            setMessage({
                                message: 'Unexpected response from server',
                                error: true
                            });
                        }
                    });
                } else {
                    // Handle HTTP error responses
                    return response.json().then(error => {
                        setIsPreviewing(false);
                        setMessage({
                            message: error.error || 'Unable to preview photo',
                            error: true
                        });
                    });
                }
            })
            .catch(error => {
                setIsPreviewing(false);
                setMessage({
                    message: error.message || 'Unable to upload photo',
                    error: true
                });
            });
    };

    const onFileUpload = () => {
        setIsUploading(true);
        fetch(`/api/upload/`, {
            method: 'POST',
            body: JSON.stringify({
                filename: selectedFile.name,
                filesize: preview.size,
                filetype: "image/bmp",
                data: preview.base64,
                barcode,
                orderId,
                uid: data.uid
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200) {
                    getPhoto();
                    setMessage({
                        message: '',
                        error: false
                    });
                    window.top.postMessage('navTop', '*');
                } else {
                    switch (response.status) {
                        case 408:
                            setMessage({
                                message: 'Temporary server error, please retry',
                                error: true
                            });
                            break;
                        default:
                            setMessage({
                                message: 'Unable to upload photo',
                                error: true
                            });
                    }
                }
            })
            .catch(() => {
                setIsUploading(false);
                setMessage({
                    message: 'Network error, please try again',
                    error: true
                });
            });
    };

    const manualChangePhoto = async () => {
        setHidePhotoRequest(true);
        try {
            const response = await fetch(`/api/replace-photo/${data.barcode}/${data.uid}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })

            if (response.ok) { // response.ok is true for any status 2xx
                setMessage({
                    message: <h3>Replaced!</h3>,
                    error: false
                });
                setSelectedFile(null);
                setCrop(null);
                setIsPreviewing(false);
                setIsUploading(false);
                setImageLoaded(false);
                setImageExists(false);
                setNewImage(null);
                setPreview(null);
                setHidePhotoRequest(false);
                getPhoto();
            } else {
                switch (response.status) {
                    case 408:
                        setMessage({ message: 'Temporary server error, please retry', error: true });
                        break;
                    default:
                        setMessage({ message: 'Unable to replace photo', error: true });
                }
            }
        } catch (error) {
            setMessage({ message: `Unable to process your request`, error: true });
        }
    }

    const notifyChangePhoto = async () => {
        setHidePhotoRequest(true);
        try {
            const response = await fetch(`/api/reset-photo/${orderId}/${data.barcode}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })

            if (response.ok) { // response.ok is true for any status 2xx
                setMessage({
                    message: process.env.REACT_APP_PHOTO_REPLACEMENT_SUCCESS,
                    error: false
                });
                setSelectedFile(null);
                setCrop(null);
                setIsPreviewing(false);
                setIsUploading(false);
                setImageLoaded(false);
                setImageExists(false);
                setNewImage(null);
                setPreview(null);
                getPhoto();
            } else {
                switch (response.status) {
                    case 408:
                        setMessage({ message: 'Temporary server error, please retry', error: true });
                        break;
                    default:
                        setMessage({ message: 'Unable to request a photo replacement', error: true });
                }
            }
        } catch (error) {
            setMessage({ message: `Unable to process your request`, error: true });
        }

    }

    return (
        <>
            {imageLoaded && !imageExists && !newImage && (
                <>
                    {!selectedFile && (
                        <Row>
                            <Col className='px-4'>
                                <h4>Upload a photo for your pass</h4>
                                <ul className='text-left'>
                                    <li>Submit one high-quality color photo showing a clear image of your face without any filters</li>
                                    <li>Take off any sunglasses, hats or items that may obscure your face</li>
                                    <li>A white or off-white background without shadows, texture, or lines works best</li>
                                    <li>Photos must be in JPG, PNG or GIF format and less than 5MB in size</li>
                                </ul>
                                <input
                                    className='button -secondary'
                                    type="file"
                                    onChange={onFileChange}
                                    accept="image/*"
                                />
                            </Col>
                        </Row>
                    )}
                    {selectedFile && !preview && (
                        <Row>
                            <Col sm={6} xs={12} className='text-center px-4 pt-5 pb-5'>
                                <h4>Please crop your photo</h4>
                                <p>Ensure the cropped image is of the ticketholder/passholder and that the entire face is clear. Any guest photographs that do not meet our requirements may be rejected.</p>
                                <Row>
                                    <Col className='px-0 mt-4'>
                                        {!isPreviewing && <button className='button -secondary mx-2' onClick={() => { setSelectedFile(null); setMessage({ message: '', error: false }); }}>Cancel</button>}
                                        {!isPreviewing && <button disabled={!crop || (crop.width === 0 || crop.height === 0)} className='button -tertiary mx-2' onClick={onFilePreview}>Preview</button>}
                                        {isPreviewing && <Loader />}
                                    </Col>
                                </Row>
                            </Col>
                            {/* </Row>
                            <Row className='text-center mt-3'> */}
                            {/* </Row>
                            <Row className='text-center'> */}
                            <Col sm={6} xs={12} className="px-0 text-center">
                                <Cropper setMessage={setMessage} selectedFile={selectedFile} setCrop={setCrop} />
                            </Col>
                        </Row>
                    )}
                    {preview && (
                        <Row className='text-center'>
                            <Col className='px-4 pt-5' sm={6}>
                                <h4>Does your photo appear correct?</h4>
                                <h5 className='text-danger mb-4'>Once uploaded this can only be changed at Guest Services.</h5>
                                {!isUploading && (
                                    <Row>
                                        <Col className='px-0 mt-4 mb-3'>
                                            <button className='button -secondary mx-2' onClick={() => { setSelectedFile(null); setPreview(null); setMessage({ message: '', error: false }); }}>Try Again</button>
                                            <button className='button -tertiary mx-2' onClick={onFileUpload}>Upload</button>
                                        </Col>
                                    </Row>
                                )}
                                {isUploading && <Loader />}
                            </Col>
                            <Col className='px-0'>
                                <img className="previewImage mb-3" src={preview.base64} alt="Preview" />
                                <h6>(Photo may appear blurry)</h6>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            {!isPassActivated && imageExists && <>
                <Row className='mt-4'>
                    <Col className='text-center px-4'>
                        <p className=''>{process.env.REACT_APP_INACTIVE_PASS}</p>
                    </Col>
                </Row>
                {!hidePhotoRequest && <Row className='mt-2'>
                    <Col className='px-0 text-center'>
                        <ConfirmButton
                            onClick={manualChangePhoto}
                            message="Are you sure you want to replace your current photo?"
                        >
                            Replace Photo
                        </ConfirmButton>
                    </Col>
                </Row>}
            </>}
            {isPassActivated && imageExists && <>
                <Row className='mt-4'>
                    <Col className='text-center px-4'>
                        <p className=''>{process.env.REACT_APP_ACTIVE_PASS}</p>
                    </Col>
                </Row>
                {!hidePhotoRequest && <Row className='mt-2'>
                    <Col className='px-0 text-center'>
                        <ConfirmButton
                            onClick={notifyChangePhoto}
                            message="By selecting 'Yes' below your information will be sent to our team requesting they remove your current photo so you can replace it in your Digital Wallet. Note this can take up to 48 hours to process and you will not be able to use your pass until a new photo is uploaded. If you plan to visit within the next 48 hours, please cancel and visit Guest Services where a Team Member can assist you in taking a new photo."
                        >
                            Replace Photo Request
                        </ConfirmButton>
                    </Col>
                </Row>}
            </>
            }
        </>
    );

}

export default Photo;