import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmButton = ({ onClick, children, body, message }) => {
    const [isOpen, setIsOpen] = useState(false);

    const executeAction = () => {
        setIsOpen(false);
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <>
            <button className="button -secondary mx-2" onClick={() => setIsOpen(true)}>
                {children}
            </button>
            <Modal centered isOpen={isOpen}>
                <ModalBody className='text-left'>
                    {body || message || 'Are you sure you wish to proceed?'}
                </ModalBody>
                <ModalFooter>
                    <button className="button -tertiary mx-2" onClick={executeAction}>Yes</button>
                    <button className="button -secondary mx-2" onClick={() => setIsOpen(false)}>Cancel</button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ConfirmButton;
