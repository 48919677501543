import React, { useEffect } from "react";
import config from "./config.json";
import Quagga from "quagga";

const BarcodeScanner = props => {
  
  const { executeCB, handleScan } = props;

  useEffect(() => {
    Quagga.init(config, err => {
      if (err) {
        console.log(err, "error msg");
      }
      Quagga.start();
      return () => {
        Quagga.stop()
      }
    });

    executeCB(() => {
      Quagga.stop();
    })

    const detected = result => {
      if (result.codeResult.code.length === 16 || result.codeResult.code.length === 18) {
        handleScan(result.codeResult.code);
        Quagga.stop();
      }
    };

    Quagga.onDetected(detected);
  }, [executeCB, handleScan]);

  return (
    // If you do not specify a target,
    // QuaggaJS would look for an element that matches
    // the CSS selector #interactive.viewport
    <div id="interactive" className="viewport" />
  );
};

export default BarcodeScanner;