import React from 'react';
import { Route, Router, Switch } from "react-router-dom";
import history from './history';
import "./App.css";
import "./Moreys.css";
import 'bootstrap/dist/css/bootstrap.css';
import Home from './components/Home/Home';
import StoredValue from './components/StoredValue/StoredValue';

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/stored-value/" component={(props) => <StoredValue {...props} />} />
        <Route path="/" component={(props) => <Home {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
