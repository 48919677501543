import React from 'react';
import { Row, Col } from 'reactstrap';

const Header = () => {

    return (
        <Row className='p-2'>
            <Col className='p-3'>
                <h5>Maximize your time and fun by keeping your tickets and passes in your digital Apple or Google Wallet! </h5>
                <h6 className='text-dark mt-4'>Using your mobile device, enter your Order ID (8 digits) and Barcode (8-18 digits).</h6>
            </Col>
        </Row>
    );
};

export default Header;
