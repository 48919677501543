import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Loader from '../Loader/Loader';
import Header from '../Includes/Header';

const Register = ({ orderId, barcode, firstName, lastName, email, phone, optInEmailMarketing, optInSMSMarketing, onChange, register, message, error, isLoading }) => {

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    let cleaned = value.replace(/\D/g, ''); // Remove all non-numeric characters
    let formatted = '';

    // Apply formatting
    if (cleaned.length > 6) {
      formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    } else if (cleaned.length > 3) {
      formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`;
    } else if (cleaned.length > 0) {
      formatted = `(${cleaned.slice(0, 3)}`;
    }

    onChange({ target: { name: e.target.name, value: formatted.replace(/\D/g, "") } });
    setPhoneMasked(formatted);
  };

  // eslint-disable-next-line
  useEffect(() => handlePhoneChange({ target: { value: phone } }), []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };

  const [phoneMasked, setPhoneMasked] = useState('');
  // handlePhoneChange({ target: { value: phone } })

  return (
    <Container className='mt-1 text-center px-0'>
      <Header />
      <Row className='p-2 align-items-end'>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Order ID</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                disabled
                className='form-control'
                value={orderId}
                type="text"
              />
            </div>
          </form>
        </Col>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Barcode</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                disabled
                className='form-control'
                value={barcode}
                type="text"
              />
            </div>
          </form>
        </Col>
      </Row>

      <Row className='mt-4 text-center'>
        <Col>
          <h3>Register Your Card:</h3>
        </Col>
      </Row>

      <Row className='p-2'>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">First Name</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                className='form-control'
                required
                name="firstName"
                id="firstName"
                type="text"
                value={firstName}
                onChange={onChange}
              />
            </div>
          </form>
        </Col>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Last Name</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                className='form-control'
                required
                name="lastName"
                id="lastName"
                type="text"
                value={lastName}
                onChange={onChange}
              />
            </div>
          </form>
        </Col>
      </Row>

      <Row className='p-2'>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Email Address</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                className='form-control'
                required
                name="email"
                id="email"
                type="email"
                value={email}
                onChange={onChange}
              />
            </div>
          </form>
        </Col>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -primary'>
            <h4 className="text-left">Phone Number</h4>
            <div className='form-control-wrap form-control-select-wrapper'>
              <input
                className='form-control'
                required
                name="phone"
                id="phone"
                type="text"
                value={phoneMasked}
                onChange={handlePhoneChange}
              />
            </div>
          </form>
        </Col>
      </Row>

      <Row className='p-2'>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -tertiary'>
            <div className='form-control-wrap form-control-select-wrapper text-left'>
              <label style={{ width: '100%' }}>
                <input
                  style={{ float: 'left', width: '50px' }}
                  className='form-control'
                  required
                  name="optInEmailMarketing"
                  id="optInEmailMarketing"
                  type="checkbox"
                  checked={optInEmailMarketing}
                  onChange={onChange}
                />
                <h4>Opt-in for Email News & Announcements</h4>
              </label>
            </div>
          </form>
        </Col>
        <Col md={6} className='pt-2 px-0'>
          <form className='form-control-group -tertiary '>
            <div className='form-control-wrap form-control-select-wrapper text-left'>
              <label style={{ width: '100%' }}>
                <input
                  style={{ float: 'left', width: '50px' }}
                  className='form-control'
                  required
                  name="optInSMSMarketing"
                  id="optInSMSMarketing"
                  type="checkbox"
                  checked={optInSMSMarketing}
                  onChange={onChange}
                />
                <h4>Opt-in for SMS Messages*</h4>
              </label>
            </div>
          </form>
        </Col>
      </Row>

      <Row className='p-0'>
        <Col md={12} className={(error ? "text-danger border border-danger " : "") + "font-weight-bold text-center"}>
          {message}&nbsp;
        </Col>
      </Row>
      <Row className='mt-4 text-center'>
        <Col>
          {!isLoading ?
            <button
              disabled={isLoading || [firstName, lastName, email, phone].some(field => !field) || !validateEmail(email) || String(phone).length < 10}
              className='button -tertiary -large'
              onClick={register}
            > Register</button>
            : <><h3>Submitting your info...</h3><Loader /></>}
        </Col>
      </Row>

      <Row className='p-2 mt-4'>
        <Col className='pt-2 px-0'>
          <p className='disclaimer'>* By selecting this checkbox and entering mobile number I agree to Opt-in to receive reminders on Park Hours, Deal, Updates, Promotions, general information and renewals from Morey’s Alerts. Message frequency varies. Text HELP to 36401 for help, Text STOP to 36401 to end. Msg&Data Rates May Apply. By opting in, I authorize Morey’s piers to deliver SMS messages using an automatic dialing system and I understand that I am not required to opt in as a condition of purchasing any property, goods, or services. By leaving this box unchecked you will not be opted in for SMS messages at this time.</p>
        </Col>
      </Row>

    </Container >
  )
};

export default Register;
