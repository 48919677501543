import React from 'react';
import { Table, Container } from 'reactstrap';

const LoginSamples = ({ orderId, barcode }) => {

    const samples = [
        ['17054864', '43732432A', 'Ticket redeemed'],
        ['15435549', '41065766A', 'Ticket not redeemed'],
        ['17247887', '44826747A', 'Ticket refunded'],
        ['17950098', '240000000000615892', 'Season Pass 1'],
        ['17650205', '240000000000638680', 'Season Pass 2'],
        ['44861335', '240030000000427109', 'Old Barcode 1'],
        ['44861335', '240130000000427109', 'New Barcode 1'],
        ['44861338', '240000000000427110', 'Old Barcode 1'],
        ['44861338', '240100000000427110', 'New Barcode 1'],
        ['19140858', '240000000000631157', 'Old Barcode 1'],
        ['19140858', '240000000000650359', 'New Barcode 1'],
        ['18458660', '240000000000618796', 'Old Catalate'],
        ['18458660', '244400000000618796', 'New Catalate'],
        ['44862248', '240500000000159326', 'Denise'],
        ['44863791', '241780000000206641', 'Dino'],
        ['44863792', '242340000000031907', 'Will'],
        ['44862250', '242320000000291275', 'Sandy'],
        ['44862254', '240500000000131908', 'Geoff'],
        ['44863804', '242160000000063788', 'Jordan'],
        ['46405029', '3459170000008465', 'Stored Account 1'],
        ['46405051', '3497130000008476', 'Stored Account 2'],
        ['19643096', '3497130000008476', 'Stored Account Invalid'],
        ['20714237', '3402000000041202', 'Gift Certificate $0'],
        ['20715031', '3402000000041090', 'Gift Certificate $50'],
        ['19643096', '3402000000046328', 'Ride Ticket Package 1'],
        ['19643096', '240000000000692617', 'Ride Ticket Package 2'],
        ['46559429', '46559429A', 'Same Day Combo 1'],
        ['46559429', '46559430', 'Same Day Combo 2'],
        ['47603239', '47603239A', 'Group Sales'],
    ]

    return (
        <Container>
            {parseInt(process.env.REACT_APP_SHOW_LOGIN_SAMPLES) === 1 && <div className='border border-danger p-1'>
                <h4 className='text-danger text-center'>For Internal Testing Only:</h4>
                <Table className='mt-5 text-left samples'>
                    <thead>
                        <tr>
                            <th>Type</th>
                            {/* <th>Order</th>
                            <th>Barcode</th>
                            <th>URL</th> */}
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>

                        {samples.map(row => (
                            <tr key={`${row[0]}${row[1]}`} className={orderId === row[0] && barcode === row[1] ? "selected" : ""}>
                                <td>{row[2]}</td>
                                {/* <td>{row[0]}</td>
                                <td>{row[1]}</td>
                                <td>/{row[0]}/{row[1]}</td> */}
                                <td><a href={`/${row[0]}/${row[1]}`}>/{row[0]}/{row[1]}</a></td>
                            </tr>
                        ))}
                        {/* <p><a href='/17054864/43732432A'>Order: 17054864 / Barcode: 43732432A (Ticket redeemed)</a></p>
                        <p><a href='/15435549/41065766A'>Order: 15435549 / Barcode: 41065766A (Ticket not redeemed)</a></p>
                        <p><a href='/17247887/44826747A'>Order: 17247887 / Barcode: 44826747A (Ticket refunded)</a></p>
                        <p><a href='/17950098/240000000000615892'>Order: 17950098 / Barcode: 240000000000615892 (Season Pass 1)</a></p>
                        <p><a href='/17650205/240000000000638680'>Order: 17650205 / Barcode: 240000000000638680 (Season Pass 2)</a></p>
                        <p><a href='/44861335/240030000000427109'>Order: 44861335 / Barcode: 240030000000427109 (Old Barcode 1)</a></p>
                        <p><a href='/44861335/240130000000427109'>Order: 44861335 / Barcode: 240130000000427109 (New Barcode 1)</a></p>
                        <p><a href='/44861338/240000000000427110'>Order: 44861338 / Barcode: 240000000000427110 (Old Barcode 2)</a></p>
                        <p><a href='/44861338/240100000000427110'>Order: 44861338 / Barcode: 240100000000427110 (New Barcode 2)</a></p>
                        <p><a href='/19140858/240000000000631157'>Order: 19140858 / Barcode: 240000000000631157 (Old Barcode 3)</a></p>
                        <p><a href='/19140858/240000000000650359'>Order: 19140858 / Barcode: 240000000000650359 (New Barcode 3)</a></p>
                        <p><a href='/18458660/240000000000618796'>Order: 18458660 / Barcode: 240000000000618796 (Old Catalate)</a></p>
                        <p><a href='/18458660/244400000000618796'>Order: 18458660 / Barcode: 244400000000618796 (New Catalate)</a></p>
                        <p><a href='/44862248/240500000000159326'>Order: 44862248 / Barcode: 240500000000159326 (Denise)</a></p>
                        <p><a href='/44863791/241780000000206641'>Order: 44863791 / Barcode: 241780000000206641 (Dino)</a></p>
                        <p><a href='/44863792/242340000000031907'>Order: 44863792 / Barcode: 242340000000031907 (Will)</a></p>
                        <p><a href='/44862250/242320000000291275'>Order: 44862250 / Barcode: 242320000000291275 (Sandy)</a></p>
                        <p><a href='/44862254/240500000000131908'>Order: 44862254 / Barcode: 240500000000131908 (Geoff)</a></p>
                        <p><a href='/44863804/242160000000063788'>Order: 44863804 / Barcode: 242160000000063788 (Jordan)</a></p>
                        <p><a href='/46405029/3459170000008465'>Order: 46405029 / Barcode: 3459170000008465 (Stored Acct 1)</a></p>
                        <p><a href='/46405051/3497130000008476'>Order: 46405051 / Barcode: 3497130000008476 (Stored Acct 2)</a></p>
                        <p><a href='/19643096/3497130000008476'>Order: 19643096 / Barcode: 3497130000008476 (Stored Acct 2)</a></p>
                        <p><a href='/19643096/3402000000046328'>Order: 19643096 / Barcode: 3402000000046328 (Ride Ticket Package 1)</a></p>
                        <p><a href='/19643096/240000000000692617'>Order: 19643096 / Barcode: 240000000000692617 (Ride Ticket Package 2)</a></p> */}
                    </tbody>
                </Table>
            </div>}
        </Container>
    );
};

export default LoginSamples;